import React from "react";
import Header from './components/Header';
import Hero from "./components/Hero";
import { Provider } from './context/CartContext';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Cart from "./components/Cart";

function App() {
  return (
    <Router>
      <div className="flex flex-col bg-bg">
        <Provider>
          <Header />
          <div>
            <Switch>
              <Route exact path='/'>
                <Hero />
              </Route>
              <Route path='/cart'>
                <Cart />
              </Route>
            </Switch>
          </div>
        </Provider>
      </div>
    </Router>
  );
}

export default App;
