import React from 'react'
import { useContext } from 'react';
import {Context} from '../context/CartContext';
import Footer from './Footer';


const Cart = () => {
    const { state, dispatch } = useContext(Context);
    const { cart } = state;
    let total= 0;

    for(const[key] of Object.entries(cart)) {
        total = total + cart[key].price * cart[key].qty;
    }
  return (
    <div className='relative flex flex-col'>
        <h1 className='flex p-4 justify-center items-center text-3xl font-extrabold tracking-widest text-black'>My Cart</h1>
        <div className='flex-1 p-6'>
            <ul className='my-6 divide-y divide-[#6dd3fe]'> 
                {Object.entries(cart).map(([key]) =>(
                    <li className='flex py-6'>
                        <div className='h-24 w-24 flex-shrink-0 rounded-md border border-[#6dd3fe]'>
                            <img src={cart[key].img} alt='' className='h-full w-full object-cover object-center'/>
                        </div>
                        <div className='ml-4 flex flex-1 flex-col'>
                            <div>
                                <div className='flex justify-between text-base font-medium text-black'>
                                    <h3>{cart[key].name}</h3>
                                    <p className='ml-4'>${cart[key].price}</p>
                                </div>
                                <p className='mt-1 text-sm text-gray-500'>{cart[key].details}</p>
                            </div>
                            <div className='flex flex-1 items-end justify-between text-sm'>
                                <p className='text-gray-500'>Qty - {cart[key].qty}</p>

                                <div className='flex'>
                                    <button onClick={() => { dispatch({ type: 'REMOVE_FROM_CART', payload: cart[key]})}} className='font-medium text-gray-600 hover:text-gray-500'>remove</button>
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
        <div className='mt-6 flex items-center justify-center flex-col'>
            <div className='text-black text-lg font-medium'>Total $ {total}</div>
            <button className='flex rounded-md border border-transparent px-12 py-3 text-black text-base font-medium bg-[#6dd3fe]'>Checkout</button>
        </div>
        <Footer />
    </div>
  )
}

export default Cart;






