import React from 'react';
import HeroImg from '../assets/hero.png';
import {RiShoppingBagFill} from 'react-icons/ri';
import {BsArrowRight} from 'react-icons/bs';
import {motion} from 'framer-motion';
import Text from './Text';
import Slider from './Slider';
import Visual from './Visual';
import Products from './Products';
import Testimonials from './Testimonials';
import Footer from './Footer';

const Hero = () => {
    const transition = {duration: 3, type: 'spring'}
  return (
   <div>
         <div className='py-0 px-8 flex lg:flex-row lg:my-8 mt-8'>
            <div className='flex basis-1/5 flex-col gap-20'>
                <span className='uppercase lg:text-2xl text-lg w-min block font-medium text-black'>skin protection cream</span>
                <div className='flex flex-col w-min text-black'>
                  <span className='font-extrabold lg:text-4xl text-2xl'>Trendy Collections</span>
                  <span className='block'>Seedily say has suitable disposal and boy. Exercise joy man children rejoiced.</span>
                </div>
            </div>

            <div className='flex lg:items-baseline relative lg:justify-center basis-3/5'>
                <motion.div
                  initial={{bottom: '2rem'}}
                  whileInView={{bottom: '0rem'}}
                  transition={transition}
                  className='lg:w-[24rem] lg:h-[24rem] w-52 h-[60%] rounded-full absolute lg:bottom-0 top-0 lg:top-auto bg-gradient-to-r from-[#0bb5ff] to-[#6dd3fe]'></motion.div>
                <motion.img
                  initial={{bottom: '-2rem'}}
                  whileInView={{bottom: '0rem'}}
                  transition={transition}
                  src={HeroImg} alt='' className='absolute lg:bottom-0 top-2 lg:top-auto lg:w-[24rem] h-[60%] lg:h-auto left-4 lg:left-auto'/>
                <motion.div
                  initial={{right: '4%'}}
                  whileInView={{right: '0%'}}
                  transition={transition}
                  className='absolute flex lg:gap-4 gap-2 items-center right-0 lg:bottom-[25%] bottom-[15%]'>
                    <RiShoppingBagFill className='lg:w-12 lg:h-12 w-10 h-10 bg-white p-2 border-4 border-solid border-black rounded-full'/>
                        <div className='flex gap-4 items-center bg-white p-2 text-sm shadow-lg rounded-2xl'>
                            <span className='block lg:w-24 w-20'>Best sign up offers</span>
                            <div className='flex rounded-full border-2 border-solid border-sky-200 w-5 h-5 p-1 items-center justify-center'>
                                <BsArrowRight />
                            </div>
                        </div>
                </motion.div>
            </div>

            <div className='lg:flex lg:basis-1/5 lg:flex-col lg:gap-20 hidden'>
                <div className='flex flex-col text-right text-black'>
                    <span className='font-extrabold text-4xl'>1.5m</span>
                    <span>Monthly Traffic</span>
                </div>
                <div className='flex flex-col text-right text-black'>
                    <span className='font-extrabold text-4xl'>100k</span>
                    <span>Happy customers</span>
                 </div>
            </div>
         </div>
         <Text />
         <Slider />
         <Visual />
         <Products />
         <Testimonials />
         <Footer />
   </div>
  )
}

export default Hero;



