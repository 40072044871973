import React from 'react'

const Text = () => {
  return (
    <div className='flex flex-row md:hidden lg:hidden p-8 items-center justify-center gap-8'>
            <div className='flex flex-col'>
                <span className='font-extrabold text-4xl'>1.5m</span>
                <span>Monthly Traffic</span>
            </div>
            <div className='flex flex-col'>
                <span className='font-extrabold text-4xl'>100k</span>
                <span>Happy customers</span>
            </div>
    </div>
  )
}

export default Text;