import React, { useContext } from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { SliderProducts } from '../data/products';
import { IoIosArrowForward, IoIosArrowBack} from 'react-icons/io';
import {Context} from '../context/CartContext';

const Slider = () => {
   const { dispatch } = useContext(Context);
  return (
    <div className='relative lg:mt-0 mt-8'>
        
        <div className='absolute cursor-pointer z-10 lg:right-24 right-0 top-1/2 image-swiper-button-next'>
            <IoIosArrowForward size={50}/>
        </div>
        <div className='absolute cursor-pointer z-10 lg:left-24 left-0 top-1/2 image-swiper-button-prev'>
            <IoIosArrowBack size={50}/>
        </div>
        <Swiper
        breakpoints={{
            640: {slidesPerView: 3},
            0: {slidesPerView: 1}
        }}
        modules={[Pagination, Navigation]}
        navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
            disabledClass: 'swiper-button-disabled'
        }}
        loopFillGroupWithBlank={true}
        slidesPerView={3}
        spaceBetween={40}
        slidesPerGroup={1}
        loop={true} className='w-[70%] h-48 mySwiper'>
            { SliderProducts.map((slide, i) => (
                <SwiperSlide className='bg-white flex p-4 rounded-xl relative overflow-hidden box-border' key={i}>
                    <div className='flex flex-col justify-between'>
                        <div className='flex flex-col'>
                            <span className='font-semibold text-2xl'>{slide.name}</span>
                            <span className='text-xl'>{slide.detail}</span>
                        </div>

                        <span className='font-bold text-4xl'>${slide.price}</span>

                        <div onClick={() => { dispatch({ type: 'ADD_TO_CART', payload: slide})}} className='text-xs border-2 border-solid border-black py-1 px-2 w-max rounded-2xl cursor-pointer hover:bg-bg'>Add to cart</div>
                    </div>
                    <img src={slide.img} alt='products' className='-rotate-12 absolute right-0 w-[40%] h-full -bottom-[20%]'/>
                </SwiperSlide>
            ))}
        </Swiper>
    </div>
  )
}

export default Slider;