import React from 'react';
import Hero from '../assets/testimonialHero.png';
import {Swiper, SwiperSlide} from 'swiper/react';
import { TestimonialsData } from '../data/testimonials';

const Testimonials = () => {
  return (
    <div className='flex flex-col justify-center items-center py-16 px-8'>
        <div className='flex lg:flex-row flex-col justify-between lg:items-end items-center w-full'>
            <div className='flex flex-col text-center text-black'>
                <span className='uppercase text-3xl font-bold mb-4'>Top rated </span>
                <span className='uppercase text-sm'>Seedily say has suitable disposal and boy.</span>
                <span className='uppercase text-sm'>Exercise joy man children rejoiced</span>
            </div>

            <img src={Hero} alt='' className='lg:w-[25rem] w-[80vw] justify-center items-center' />

            <div className='lg:text-right flex flex-col gap-4 text-center text-black'>
                <span className='uppercase text-3xl font-bold'>100k</span>
                 <span className='uppercase text-sm'>Happy customers with us</span>
            </div>
        </div>

        <div className='text-2xl font-bold uppercase mb-8 text-black'>Reviews</div>
        
        <div className='w-full'>
            <Swiper
              slidesPerView={3}
              spaceBetween={20}
              slidesPerGroup={1}
              className='p-8'
              breakpoints={{
                640: {slidesPerView: 3},
                0: {slidesPerView: 1}
              }}>
                {
                    TestimonialsData.map((testimonial, i)=> (
                        <SwiperSlide key={i}>
                            <div className='px-4 pb-4 flex flex-col relative bg-white items-center justify-center rounded-lg shadow-3xl'>
                                <img src={testimonial.image} alt='' className='w-12'/>
                                <span className='items-center text-xs tracking-wide my-6'>{testimonial.comment}</span>
                                <hr className='h-[2px] w-4/5 bg-gray-300 border-none'/>
                                <span className='font-medium'>{testimonial.name}</span>
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    </div>
  )
}

export default Testimonials;