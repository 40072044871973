import React from 'react';
import Logo from '../assets/logo.png';
import { InboxIcon, PhoneIcon, MapPinIcon, ArrowRightOnRectangleIcon, UsersIcon, LinkIcon, } from '@heroicons/react/24/outline';

const Footer = () => {
  return (
    <div className='w-full mt-20'>
        <hr className='w-full h-[1px] border-none bg-white mt-4'/>
        <div className='flex w-full lg:justify-around p-4 justify-center flex-wrap lg:flex-nowrap gap-8 items-start'>
            <div className='lg:flex gap-4 items-start hidden'>
                <img src={Logo} alt=''  className='w-9 h-9'/>
                <span className='font-bold text-base'>Amazon</span>
            </div>
            <div className='flex flex-col gap-4 max-w-[12rem]'>
                <div className='flex flex-col gap-4 w-full italic'>
                    <span className='font-medium text-xl not-italic'>Contact us</span>
                    <span className='flex gap-4 cursor-pointer'>
                        <MapPinIcon className='w-[25px]'/>
                        <span>111 north avenue Orlando, FL</span>
                    </span>
                    <span className='flex gap-4 cursor-pointer'>
                        <PhoneIcon className='w-[25px]'/>
                        <span>352-344-5893</span>
                    </span>
                    <span className='flex gap-4 cursor-pointer'>
                        <InboxIcon className='w-[25px]'/>
                        <span>support@gmail.com</span>
                    </span>
                </div>
            </div>

            <div className='flex flex-col gap-4 max-w-[12rem]'>
                <div className='flex flex-col gap-4 w-full italic'>
                    <span className='font-medium text-xl not-italic'>Account</span>
                    <span className='flex gap-4 cursor-pointer'>
                        <ArrowRightOnRectangleIcon className='w-[25px]'/>
                        <span>sign in</span>
                    </span>
                </div>
            </div>

            <div className='flex flex-col gap-4 max-w-[12rem]'>
                <div className='flex flex-col gap-4 w-full italic'>
                    <span className='font-medium text-xl not-italic'>Company</span>
                    <span className='flex gap-4 cursor-pointer'>
                        <UsersIcon className='w-[25px]'/>
                        <span>About us</span>
                    </span>
                </div>
            </div>

            <div className='flex flex-col gap-4 max-w-[12rem]'>
                <div className='flex flex-col gap-4 w-full italic'>
                    <span className='font-medium text-xl not-italic'>Resources</span>
                    <span className='flex gap-4 cursor-pointer'>
                        <LinkIcon className='w-[25px]'/>
                        <span>sign in</span>
                    </span>
                </div>
            </div>
        </div>

        <div className='flex text-center text-base mt-8 justify-center'>
            <span>All right Reserved 2022</span>
        </div>
        
    </div>
  )
}

export default Footer;