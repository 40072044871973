import React from 'react';
import Shade from '../assets/shade.png';
import ReactCompareImage from 'react-compare-image';
import Before from '../assets/before.png';
import After from '../assets/after.png';

const Visual = () => {
  return (
    <div className='mb-24 p-8 flex lg:justify-around lg:flex-row flex-col justify-center items-center'>
        <div className='flex flex-col gap-8 relative text-black'>
            <span className='lg:text-3xl text-2xl uppercase block font-bold'>Virtual Try-on</span>
            <span className='lg:text-3xl text-2xl uppercase block w-80'>Never buy the wrong shade again</span>
            <span className='lg:text-3xl text-2xl font-bold'>Try now!</span>
            <img src={Shade} alt='' className='w-40'/>
        </div>

        <div>
            <div className='lg:w-[30rem] w-[24rem]'>
                <ReactCompareImage leftImage={Before} rightImage={After} />
            </div>
        </div>
    </div>
  )
}

export default Visual;