import React, { useState, useRef, useEffect, useContext } from 'react';
import Plane from '../assets/plane.png';
import { ProductsData } from '../data/products';
import autoAnimate from '@formkit/auto-animate'
import {Context} from '../context/CartContext';

const Products = () => {

    const parent = useRef(null);

    useEffect(() => {
        parent.current && autoAnimate(parent.current)
      }, [parent])

    const [menuProducts, setMenuProducts] = useState(ProductsData);
    const filter = (type) => {
        setMenuProducts(ProductsData.filter((product)=>product.type === type))
    };
    const { dispatch } = useContext(Context);

  return (
    <div className='px-8 flex flex-col relative gap-8 items-center justify-center'>
        <img src={Plane} alt='' className='absolute w-32 left-[30%] lg:-top-12 -top-16' />
        <h1 className='text-2xl font-bold text-center text-black'>Our Featured products</h1>

        <div className='grid w-[90%] grid-cols-[25%_auto]'>
            <ul className='flex flex-col lg:gap-8 font-medium text-xl lg:ml-0 -ml-8 gap-2 flex-wrap lg:flex-nowrap w-full text-black'>
                <li className='hover:text-pink cursor-pointer' onClick={()=>setMenuProducts(ProductsData)}>All</li>
                <li className='hover:text-pink cursor-pointer' onClick={()=>filter('skin care')}>Skin Care</li>
                <li className='hover:text-pink cursor-pointer' onClick={()=>filter('conditioner')}>Conditioners</li>
                <li className='hover:text-pink cursor-pointer' onClick={()=>filter('foundation')}>Foundations</li>
            </ul>

            <div className='flex flex-wrap gap-y-8 gap-x-4 overflow-y-scroll h-[25rem] ml-4 lg:ml-0' ref={parent}>
                {
                    menuProducts.map((product, i) => (
                        <div className='flex w-[200px] h-40 bg-white relative overflow-hidden p-4 rounded-2xl' key={i}>
                            <div className='flex flex-col'>
                                <span className='font-semibold text-2xl'>{product.name}</span>
                                <span className=''>{product.detail}</span>
                                <span className='font-semibold text-xl'>{product.price}$</span>
                                <div onClick={() => { dispatch({ type: 'ADD_TO_CART', payload: product})}} className='text-xs border-2 border-solid border-black py-1 px-2 w-max rounded-2xl cursor-pointer hover:bg-bg'>Add to cart</div>
                            </div>
                            <img src={product.img} alt='products' className='-rotate-12 absolute right-0 w-24 h-44 top-16'/>
                        </div>
                    ))
                }
            </div>
        </div>
    </div>
  )
}

export default Products;