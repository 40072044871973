import React,  {useState, useContext} from 'react';
import Logo from '../assets/logo.png';
import {CgShoppingBag} from 'react-icons/cg';
import {GoThreeBars} from 'react-icons/go';
import {Context} from '../context/CartContext';
import {Link} from 'react-router-dom';




const Header = () => {

  const [showMenu, setShowMenu] = useState(true);

  const toggleMenu = () => {
    setShowMenu((showMenu)=>!showMenu)
  }

  const { state } = useContext(Context);
  console.log(state);

  const { cart } = state
  let itemCount = 0

  for(const [key] of Object.entries(cart)) {
    itemCount = itemCount + cart[key].qty 
  }
  
  return (
    <div className='flex p-6'>
        <div className='flex items-center space-x-4 flex-1'>
           <Link to='/'>
            <img src={Logo} alt='' className='w-10 h-10'/>
           </Link>
           <Link to='/'><span className='font-semibold items-center'>Amazon</span></Link>
        </div>

        <div className='flex lg:gap-8 items-center justify-center lg:relative absolute right-8 z-[9999] lg:z-0 bg-white lg:bg-transparent text-black rounded-md flex-col lg:flex-row p-4 lg:p-0'>
            <div>
              <GoThreeBars className='lg:hidden block mb-4' onClick={toggleMenu}/>
            </div>

                <ul className='lg:flex gap-8 font-medium flex-col lg:flex-row lg:-ml-8 hidden' style={{display: showMenu? 'inherit' : 'none'}}>
                    <li className='hover:text-[#fe956f] cursor-pointer' onClick={toggleMenu}>Collections</li>
                    <li className='hover:text-[#fe956f] cursor-pointer' onClick={toggleMenu}>Brands</li>
                    <li className='hover:text-[#fe956f] cursor-pointer' onClick={toggleMenu}>News</li>
                    <li className='hover:text-[#fe956f] cursor-pointer' onClick={toggleMenu}>Sales</li>
                    <li className='hover:text-[#fe956f] cursor-pointer' onClick={toggleMenu}>ENG</li>
                </ul>
            <input type="text" placeholder='Search' className='w-28 outline-none border-none rounded-md p-2 hidden lg:flex'/>
            <div className='flex gap-2'>
              <Link to='/cart'><CgShoppingBag className='w-6 h-6 cursor-pointer' onClick={toggleMenu}/></Link>
              <span>{itemCount}</span>
            </div>
        </div>
    </div>
  )
}


export default Header;